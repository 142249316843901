import { useState } from 'react'
import './Form.scss'
import { useNavigate } from 'react-router-dom'
import { colors, categories, typeDecoration, logoPosition, labels } from '../../../data/data'
import { MultiSelect } from "react-multi-select-component";
import InputImageColor from './inputImageColor/InputImageColor';
import { storage } from '../../../config/firebase-config';
import { addProduct, setImageLink } from '../../../services/caller.service';



function Form({data}) {
  let navigate = useNavigate()

  const [selectedColor, setColorSelected] = useState([]);
  const [selectedTypeDecoration, setSelectedTypeDecoration] = useState([]);
  const [selectedLogoPosition, setLogoPosition] = useState([]);
  const [selectedLabels, setSelectedLabels] = useState([]);

  const [listeImageColor, setListeImageColor] = useState([]);
  const [mainImage, setMainImage] = useState(null)
  const [secondaryImage1, setSecondaryImage1] = useState(null)
  const [secondaryImage2, setSecondaryImage2] = useState(null)
  const [secondaryImage3, setSecondaryImage3] = useState(null)
  const [secondaryImage4, setSecondaryImage4] = useState(null)
  const [secondaryImage5, setSecondaryImage5] = useState(null)

  const imagesLinkTab = []

  const [credentials, setCredentials] = useState({
    nom: "",
    description: "",
    specification: "",
    customization: "",
    production: "",
    categorie: "",
    sousCategorie: "",
    quantiteMin: "",
    quantiteStock: "",
    prixUniteMin: "",
    prixUniteMax: "",
    tempsLivraison:"",
    reference:"",
    typeDecoration: {},
    logoPosition: {},
    lables:{},
    colors:{}
  })

  const onChange = (e) => {
    console.log(e.target.name)
    console.log(e.target.value)
    setCredentials({
      ...credentials,
      [e.target.name]: e.target.value //Quand on passe dans onchange on fusionne l'ancien etat avec la nouveauté
    })
  }

  const onChargeImage = (e) => {
    let newfield = { color: e.target.name, img: e.target.files[0] }
    setListeImageColor([...listeImageColor, newfield])
  }

  const onSubmit = async (e) => {
    e.preventDefault()
    credentials.typeDecoration = selectedTypeDecoration
    credentials.logoPosition = selectedLogoPosition
    credentials.lables = selectedLabels
    credentials.colors = selectedColor
    
    const docRef = await addProduct(credentials)

    uploadImage(mainImage, "mainImage", docRef)
    uploadImage(secondaryImage1, "secondaryImage1", docRef)
    uploadImage(secondaryImage2, "secondaryImage2", docRef)
    uploadImage(secondaryImage3, "secondaryImage3", docRef)
    uploadImage(secondaryImage4, "secondaryImage4", docRef)
    uploadImage(secondaryImage5, "secondaryImage5", docRef)
    

    

    listeImageColor.forEach(imageColor => {
      uploadImage(imageColor.img, imageColor.color, docRef)
    })
    const imagesLink = {imagesLinkTab}
    await setImageLink(docRef, imagesLink)
    navigate('/private/addProduct')
  }

  const uploadImage = (image, nomImage, docRef) => {
    if (image != null) {
      storage.ref('products-images/' + docRef + "/" + nomImage).put(image)
        .on("state_changed", console.log("sucess"), alert)
        imagesLinkTab.push(nomImage)
    }
  }

  const categoriesOptionGenerator = () => {
    let options = []
    let key = 100
    options.push(<option key={key} value={"none"}>{"Choisir une catégorie"}</option>)
    categories.forEach(categorie => {
      key++
      options.push(<option key={key} value={categorie.nom}>{categorie.nom}</option>)
    })
    return options
  }

  const sousCategoriesOptionGenerator = () => {
    let options = []
    let key = 200
    let listeSousCategorie = []
    options.push(<option key={key} value={"none"}>{"Choisir une sous catégorie"}</option>)
    categories.forEach(categorie => {
      if (credentials.categorie === categorie.nom) {
        listeSousCategorie = categorie.sousCategorie
      }
    })

    listeSousCategorie.forEach(categorie => {
      key++
      options.push(<option key={key} value={categorie}>{categorie}</option>)
    })
    return options
  }

  return (
    <form className="Form_add" onSubmit={onSubmit}>
      <div className="input-wrapper">
        <label className="input-wrapper_label" htmlFor="nom">Nom</label>
        <input
          type="text"
          id="nom"
          name="nom"
          value={credentials.nom}
          onChange={onChange}
          className="input-wrapper_input"
        />
      </div>
      <div className="input-wrapper">
        <label className="input-wrapper_label" htmlFor="reference">Reference</label>
        <input
          type="text"
          id="reference"
          name="reference"
          value={credentials.reference}
          onChange={onChange}
          className="input-wrapper_input"
        />
      </div>
      <div className="input-wrapper">
        <label className="input-wrapper_label" htmlFor="description">Description</label>
        <textarea
          type="text"
          id="description"
          name="description"
          value={credentials.description}
          className="input-wrapper_input"
          onChange={onChange}
        />
      </div>
      <div className="input-wrapper">
        <label className="input-wrapper_label" htmlFor="specification">Spécification</label>
        <textarea
          type="text"
          id="specification"
          name="specification"
          value={credentials.specification}
          className="input-wrapper_input"
          onChange={onChange}
        />
      </div>
      <div className="input-wrapper">
        <label className="input-wrapper_label" htmlFor="customization">Customisation</label>
        <textarea
          type="text"
          id="customization"
          name="customization"
          value={credentials.customization}
          className="input-wrapper_input"
          onChange={onChange}
        />
      </div>
      <div className="input-wrapper">
        <label className="input-wrapper_label" htmlFor="production">Production</label>
        <textarea
          type="text"
          id="production"
          name="production"
          value={credentials.production}
          className="input-wrapper_input"
          onChange={onChange}
        />
      </div>
      <div className="input-wrapper">
        <label className="input-wrapper_label" htmlFor="tempsLivraison">Temps de Livraison</label>
        <textarea
          type="text"
          id="tempsLivraison"
          name="tempsLivraison"
          value={credentials.tempsLivraison}
          className="input-wrapper_input"
          onChange={onChange}
        />
      </div>
      <div className="input-wrapper">
        <label className="input-wrapper_label" htmlFor="categorie">Catégorie</label>
        <select
          id="categorie"
          name="categorie"
          value={credentials.categorie}
          className="input-wrapper_input"
          onChange={onChange}
        >
          {categoriesOptionGenerator()}
        </select>
      </div>
      <div className="input-wrapper">
        <label className="input-wrapper_label" htmlFor="sousCategorie">Sous catégorie</label>
        <select
          id="sousCategorie"
          name="sousCategorie"
          value={credentials.sousCategorie}
          className="input-wrapper_input"
          onChange={onChange}
        >
          {sousCategoriesOptionGenerator()}
        </select>
      </div>
      <div className="input-wrapper">
        <label className="input-wrapper_label" htmlFor="quantiteMin">Quantité minimum</label>
        <input
          type="number"
          id="quantiteMin"
          name="quantiteMin"
          value={credentials.quantiteMin}
          className="input-wrapper_input"
          onChange={onChange}
        />
      </div>
      <div className="input-wrapper">
        <label className="input-wrapper_label" htmlFor="quantiteStock">Quantité stock</label>
        <input
          type="number"
          id="quantiteStock"
          name="quantiteStock"
          value={credentials.quantiteStock}
          className="input-wrapper_input"
          onChange={onChange}
        />
      </div>
      <div className="input-wrapper">
        <label className="input-wrapper_label" htmlFor="prixUniteMin">Prix unité min</label>
        <input
          type="number"
          id="prixUniteMin"
          name="prixUniteMin"
          value={credentials.prixUniteMin}
          className="input-wrapper_input"
          onChange={onChange}
        />
      </div>
      <div className="input-wrapper">
        <label className="input-wrapper_label" htmlFor="prixUniteMax">Prix unité max</label>
        <input
          type="number"
          id="prixUniteMax"
          name="prixUniteMax"
          value={credentials.prixUniteMax}
          className="input-wrapper_input"
          onChange={onChange}
        />
      </div>
      <div className="input-wrapper">
        <label className="input-wrapper_label" htmlFor="typeDecoration">Select le type de décoration</label>
        <pre>{JSON.stringify(selectedTypeDecoration)}</pre>
        <MultiSelect
          options={typeDecoration}
          value={selectedTypeDecoration}
          onChange={setSelectedTypeDecoration}
          labelledBy="Select"
        />
      </div>
      <div className="input-wrapper">
        <label className="input-wrapper_label" htmlFor="logoPosition">Select les position possible pour le logo</label>
        <pre>{JSON.stringify(selectedLogoPosition)}</pre>
        <MultiSelect
          options={logoPosition}
          value={selectedLogoPosition}
          onChange={setLogoPosition}
          labelledBy="Select"
        />
      </div>
      <div className="input-wrapper">
        <label className="input-wrapper_label" htmlFor="labels">Select les labels</label>
        <pre>{JSON.stringify(selectedLabels)}</pre>
        <MultiSelect
          options={labels}
          value={selectedLabels}
          onChange={setSelectedLabels}
          labelledBy="Select"
        />
      </div>
      <div className="input-wrapper">
        <label className="input-wrapper_label" htmlFor="mainImage">Image principale</label>
        <input
          type="file"
          id="mainImage"
          name="mainImage"
          className="input-wrapper_input"
          accept=".jpg, .jpeg, .png"
          onChange={(event) => setMainImage(event.target.files[0])}
        />
      </div>
      <div className="input-wrapper">
        <label className="input-wrapper_label" htmlFor="secondaryImage1">Image secondaire 1</label>
        <input
          type="file"
          id="secondaryImage1"
          name="secondaryImage1"
          className="input-wrapper_input"
          accept=".jpg, .jpeg, .png"
          onChange={(event) => setSecondaryImage1(event.target.files[0])}
        />
        <label className="input-wrapper_label" htmlFor="secondaryImage2">Image secondaire 2</label>
        <input
          type="file"
          id="secondaryImage2"
          name="secondaryImage2"
          className="input-wrapper_input"
          accept=".jpg, .jpeg, .png"
          onChange={(event) => setSecondaryImage2(event.target.files[0])}
        />
        <label className="input-wrapper_label" htmlFor="secondaryImage3">Image secondaire 3</label>
        <input
          type="file"
          id="secondaryImage3"
          name="secondaryImage3"
          className="input-wrapper_input"
          accept=".jpg, .jpeg, .png"
          onChange={(event) => setSecondaryImage3(event.target.files[0])}
        />
        <label className="input-wrapper_label" htmlFor="secondaryImage4">Image secondaire 4</label>
        <input
          type="file"
          id="secondaryImage4"
          name="secondaryImage4"
          className="input-wrapper_input"
          accept=".jpg, .jpeg, .png"
          onChange={(event) => setSecondaryImage4(event.target.files[0])}
        />
        <label className="input-wrapper_label" htmlFor="secondaryImage5">Image secondaire 5</label>
        <input
          type="file"
          id="secondaryImage5"
          name="secondaryImage5"
          className="input-wrapper_input"
          accept=".jpg, .jpeg, .png"
          onChange={(event) => setSecondaryImage5(event.target.files[0])}
        />
      </div>
      <div className="input-wrapper">
        <label className="input-wrapper_label" htmlFor="color">Select color</label>
        <pre>{JSON.stringify(selectedColor)}</pre>
        <MultiSelect
          options={colors}
          value={selectedColor}
          onChange={setColorSelected}
          labelledBy="Select"
        />
      </div>
      <InputImageColor
        selected={selectedColor}
        listeImageColor={listeImageColor}
        onChargeImage={onChargeImage}
      />
      <button className="sign-in-button">Ajouter</button>
    </form>
  )
}

export default Form;