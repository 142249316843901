import './InputImageColor.scss'

function InputImageColor({ selected, listeImageColor, onChargeImage }) { 

    const listeinputsGenerator = () => {
        let options = []
        let key = 0
        let labelName = ""
        selected.forEach(color => {
            key++
            console.log(color.label)
            console.log(listeImageColor)
            options.push(<label className="input-wrapper_label" key={key}>Image pour la couleur {color.label}</label>)
            options.push(<input type="file"
                id={labelName}
                name={color.label}
                value={listeImageColor.img}
                className="input-wrapper_input"
                onChange={onChargeImage}
            />)
        })
        return options 
    }




    return (
        <>
            {listeinputsGenerator()}
        </>
    )
}

export default InputImageColor;
