import React from 'react';
import "./Login.scss";
import logoLink from '../../../assets/img/logo.png';
import Form from '../../../components/public/from/Form';

export const Login = () => {
  return (
    <main className='Login'>
      <div className='Login_container'>
        <img src={logoLink}></img>
        <Form/>
      </div>
    </main>
  )
}
export default Login