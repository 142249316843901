import { useContext, useState } from 'react'
import './Form.scss'
import { useNavigate } from 'react-router-dom'
import { createUserWithEmailAndPassword, signInWithPopup } from 'firebase/auth'
import { auth, googleProvider } from '../../../config/firebase-config'
import { UserContext } from '../../../context/UserContext'
//import { accountService } from '../../../_services'


function Form() {
  let navigate = useNavigate()
  const {logIn} = useContext(UserContext)
  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
  })
  const [checked, setCheck] = useState(false)

  const onChange = (e) => {
    console.log(e.target.name)
    console.log(e.target.value)

    setCredentials({
      ...credentials,
      [e.target.name]: e.target.value //Quand on passe dans onchange on fusionne l'ancien etat avec la nouveauté
    })
  }

  const onSubmit = async (e) => {
    e.preventDefault()
    try {
      await logIn(
        credentials.email,
        credentials.password
      )
      
      
      navigate("/private/addProduct")
    } catch (err) {
      console.log(err)
    }
  }


  const handleChangeCheckBox = () => {
    setCheck(!checked)
  }

  return (
    <form className="Form_login" onSubmit={onSubmit}>
      <div className="input-wrapper">
        <label className="input-wrapper_label" htmlFor="email">Email</label>
        <input
          type="text"
          id="email"
          name="email"
          value={credentials.email}
          onChange={onChange}
          className="input-wrapper_input"
        />
      </div>
      <div className="input-wrapper">
        <label className="input-wrapper_label" htmlFor="password">Password</label>
        <input
          type="password"
          id="password"
          name="password"
          value={credentials.password}
          className="input-wrapper_input"
          onChange={onChange}
        />
      </div>
      <button className="sign-in-button">Login</button>
    </form>
  )
}

export default Form;