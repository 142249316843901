import { Route, Routes } from "react-router-dom";
import Login from './login/Login';
import Layout from './layout/Layout';
import AddProduct from "../private/addProducts/AddProduct";



function PublicRoute() {
  
  return (
    <Routes>
      <Route element={<Layout />}>
        <Route path='/' element={<Login />} />
      </Route>
    </Routes>
  )
}

export default PublicRoute