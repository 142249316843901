export const colors = [
    {label:"Black", value:"#000000" },
    {label:"White", value:"#FFF" },
    {label:"Ecru", value:"#FBF5E1" },

    {label:"Dark Grey", value:"#383739" },
    {label:"Slate grey", value:"#383739" },
    {label:"Grey", value:"#939393" },
    {label:"Clear", value:"#E8E8E8" },
    {label:"Smoke", value:"#3E3E3E" },
    {label:"Silver", value:"#979797" },

    {label:"Light Green", value:"#B4EBB3" },
    {label:"Green", value:"#41AD41" },
    {label:"Kaki", value:"#517128" },
    {label:"Dark Green", value:"#136712" },

    {label:"Light Blue", value:"#91C1EE" },
    {label:"Blue", value:"#333FFF" },
    {label:"Dark Blue", value:"#111043" },
    {label:"Turquoise", value:"#3FA28A" },

    {label:"Red", value:"#FB0000" },
    {label:"Dark Red", value:"#710202" },
    {label:"Maroon", value:"#710202" },
    
    {label:"Light Yellow", value:"#F8F67E" },
    {label:"Yellow", value:"#FBF857" },
    {label:"Gold", value:"#9E9B0C" },

    {label:"Pink", value:"#F88BF6" },
    {label:"Lilas", value:"#FEC9FD" },

    {label:"Orange", value:"#FA7F4F" },

    {label:"Brown", value:"#713E0E" },
    {label:"Craft", value:"#713E0E" },
    {label:"Beige", value:"#F4D1B1" },
]
    
export const typeDecoration = [
    {label:"Printing", value:"Printing" },
    {label:"Embroidery", value:"Embroidery" },
    {label:"DigitalPrinting", value:"Digital printing"},
    {label:"PadPrint", value:"Pad print"},
    {label:"LaserEngraving", value:"Laser engraving"},
    {label:"SilkScreenRound", value:"Silk screen round"},
    {label:"SilkScreenPrint", value:"Silk screen print"},
    {label:"Doming", value:"Doming"},
    {label:"HotStamping", value:"Hot stamping"},
    {label:"DigitalStiker", value:"Digital stiker"},
    {label:"Screenprint", value:"Screenprint"},
    {label:"HotStamping", value:"Hot stamping"},
    {label:"Embossing", value:"Embossing"},
    {label:"ScreenTransfer", value:"Screen transfer"},
    {label:"Transfer", value:"Transfer"},
    {label:"TransferReflective", value:"Transfer reflective"},
    {label:"DigitalTransfer", value:"Digital transfer"},
    {label:"DigitalPrintPaper", value:"Digital print paper"},
    {label:"Screenround", value:"Screenround"},
    {label:"360DigitalPrint", value:"360 Digital print"},
    {label:"360LaserEngraving", value:"360 Laser Engraving"},
    {label:"FullColorDoming", value:"Full color doming" },
    {label:"RoundScreenPrinting ", value:"Round screen printing"}


]

export const logoPosition = [
    {label:"Front", value:"Front" },
    {label:"Top", value:"Top" },
    {label:"Wrap", value:"Warp" },
    {label:"Roundscreen", value:"Roundscreen" },
    {label:"RightHanded", value:"Right handed" },
    {label:"LeftHanded", value:"Left handed" },
    {label:"OppositeOfHanded", value:"Opposite of handed" },
    {label:"Back", value:"Back" },
    {label:"Left", value:"Left" },
    {label:"Right", value:"Right" },
    {label:"Around", value:"Around" },
    {label:"PaperBand", value:"PaperBand" },
    {label:"2ndNotebook", value:"2nd notebook" },
    {label:"1stNotebook", value:"1st notebook" },
    {label:"Bottom left", value:"BottomLeft" },
    {label:"BottomRight", value:"BottomRight" },
    {label:"Umbrella", value:"Umbrella" },
    {label:"Pouch", value:"Pouch" },
    {label:"Lid", value:"Lid" }

]

export const labels = [
    {label:"Ceramic", value:"Ceramic" },
    {label:"Natural", value:"Natural" },
    {label:"Glass", value:"Glass" },
    {label:"Rpet", value:"Rpet" },
    {label:"RecycledStainlesssteel", value:"Recycled Stainless steel" },
    {label:"Steel", value:"Steel" },
    {label:"StainlessSteel", value:"Stainless Steel" },
    {label:"Recycled", value:"Recycled" },
    {label:"CamelBak", value:"CamelBak" },
    {label:"Moleskine", value:"Moleskine" },
    {label:"StonePaper", value:"Stone Paper" },
    {label:"Seed", value:"Seed" },
    {label:"Hemp", value:"Hemp" },
    {label:"MadeInEurope", value:"Made in Europe" },
    {label:"AppleLeather", value:"Apple Leather" },
]

export const categories = [
        {
            "nom": "Drinkware",
            "sousCategorie": ["Bottles", "Cups", "Mugs", "Straws", "Trumblers"],
            "reference": "01"
        },
        {
            "nom": "Bags",
            "sousCategorie": ["Backpacks", "Totes", "Duffels", "Pouches", "Laptop Bags"],
            "reference": "02"
        },
        {
            "nom": "Textile",
            "sousCategorie": ["Hoodies", "Headwear", "Jackets", "Sportswear", "T-shirts", "Sweatshirts", "Joggings"],
            "reference": "03"
        },
        {
            "nom": "Office",
            "sousCategorie": ["Notebook", "Writing"],
            "reference": "04"
        },
        {
            "nom": "Home",
            "sousCategorie": ["Blankets"],
            "reference": "04"
        },
        {
            "nom": "Food",
            "sousCategorie": ["Food Container"],
            "reference": "04"
        },
        {
            "nom": "Outside",
            "sousCategorie": ["Umbrella"],
            "reference": "05"
        }
    
]