import './App.scss';
import { BrowserRouter, Routes, Route } from "react-router-dom"
import PublicRoute from './pages/public/PublicRoute';
import AddProduct from './pages/private/addProducts/AddProduct';
import Private from './pages/private/Private';
import Login from './pages/public/login/Login';



function App() {
  return (
    <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path='/private' element={<Private />}>
            <Route path='/private/addProduct' element={<AddProduct/>}></Route>
          </Route>
        </Routes>
    </BrowserRouter>
  );
}

export default App;
