import { collection, getDocs, doc, getDoc, setDoc, addDoc, updateDoc } from "firebase/firestore";
import { db, fireStore } from "../config/firebase-config";
import { getStorage } from "firebase/storage";


export const getCollection = async (categorie) => {
    const querySnapshot = await getDocs(collection(fireStore, categorie));
    const data = []
    querySnapshot.forEach((doc) => {
        data.push(doc.data())
        console.log(doc.id, " => ", doc.data());
    })
    return data
}

export const addProduct = async (product) => { 
    const collectionRef= collection(db, "Products")
    const payload = product
    const docRef = await addDoc(collectionRef, payload)
    return docRef.id
}

export const setImageLink = async (productId, images) => {
    const imageLink = doc(db, "Products", productId);
    await updateDoc(imageLink, images);
}


