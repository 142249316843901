import "./AddProduct.scss"
import Form from "../../../components/private/form/Form";
import { getCollection } from '../../../services/caller.service'
import { useState, useEffect } from 'react'


function AddProduct() {
    const [data, setData] = useState(null)
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        const fetchData = async () => {
            setData(await getCollection("Products"))
            setLoading(true)
        }
        fetchData()
    }, [loading])



    if (loading) {
        console.log(data.length)
        return (
            <main className="AddProduct">
                <div className="AddProduct_container">
                    <h1>Ajouter un produits</h1>
                    <Form data={data} />
                </div>
            </main>
        );
    } else { 
        return(
            <main className="AddProduct">
            <div className="AddProduct_container">
                <h1>Chargement</h1>
            </div>
        </main>
        )
    }
    
}

export default AddProduct;