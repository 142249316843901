import React, { useContext } from 'react';
import {UserContext} from "../../context/UserContext";
import { Navigate, Outlet } from 'react-router';

const Private = () => {
    const {currentUser} = useContext(UserContext)


    if(!currentUser) {
        return <Navigate to={'/'}/>
    }

    return (
        <div className="container">
            <Outlet />
        </div>
    );
};

export default Private;