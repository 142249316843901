import { createContext, useEffect, useState } from "react";
import { signInWithEmailAndPassword, onAuthStateChanged } from "firebase/auth";
import { auth } from "../config/firebase-config";
export const UserContext = createContext()

export function UserContextProvider (props){

    const [currentUser, setCurrentUser] = useState()
    const [loadingData, setLoadingData] = useState(true)
    console.log(currentUser)

    const logIn = (email, password) => {
        signInWithEmailAndPassword(auth, email, password)
    }

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            setCurrentUser(currentUser)
            setLoadingData(false)
        })

        return unsubscribe
    },[])

    return(
        <UserContext.Provider value={{currentUser, logIn}}>
            {!loadingData && props.children}
        </UserContext.Provider>
    )
}