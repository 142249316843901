import { Outlet } from "react-router";

function Layout() {
    return (
        <>
            <Outlet></Outlet>
        </>
    )
}

export default Layout;